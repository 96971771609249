import { FASTElement, css, customElement, html, observable } from "@microsoft/fast-element";

// TODO: the slots here doesn't really work, as we have no hooks to bind on the slots
const template = html<ConfirmationModal>/*html*/`
<style>
    @import url('assets/css/base.css');
</style>
<div>
    <slot name="question">${x => x.question}</slot>
    <div class="actions">
        <slot name="confirm">
            <button class="button button-danger"
                @click="${(x, e) => x.approve(e.event)}">${x => x.confirmText}</button>
        </slot>    
        <slot name="cancel">
            <button class="button button-success"
                @click="${(x, e) => x.cancel(e.event)}">Cancel</button>
        </slot>
    </div>
</div>
`

const styles = css`
.actions {
    margin-top: 1rem;
}
`

@customElement({
    name: 'confirmation-modal',
    template: template,
    styles: styles
})
export class ConfirmationModal extends FASTElement {
    @observable question: string = "Are you sure?"
    @observable confirmText: string = "Yes"
    constructor() {
        super();
    }
    connectedCallback() {
        super.connectedCallback();
    }
    approve(e: Event) {
        this.$emit('approve', e);
    }
    cancel(e: Event) {
        this.$emit('cancel', e);
    }
}